<template>
  <div class="card">
    <div class="input-field">
      <label class="input-field__label">{{ $t('modelFlow.upload.modelOutputArgs') }}</label>
      <div class="input-field__text">
        {{ columnInfo.modelColumnName ? `${columnInfo.modelColumnName} (${columnInfo.columnStatsType})` : columnInfo.modelDataFrameName }}
      </div>
    </div>
    <div class="input-field">
      <label class="input-field__label">{{ isMultiDatasetModel ? $t('modelFlow.upload.correspondingDataFrame') : $t('modelFlow.upload.outputColumnName') }}</label>
      <div class="input-field__input">
        <svg-icon
          icon-class="go-right"
          class="icon"
        />
        <div
          v-if="isMultiDatasetModel"
          class="select-wrapper"
        >
          <default-select
            v-validate="'required'"
            :option-list="optionList"
            :placeholder="$t('miniApp.chooseDataSource')"
            v-model="columnInfo.targetDataSourceId"
            filterable
            class="input-field__select-multi"
            :name="`${columnInfo.id}-dataSourceId`"
          />
          <div
            v-show="errors.has(`${columnInfo.id}-dataSourceId`)"
            class="error-text"
          >
            {{ errors.first(`${columnInfo.id}-dataSourceId`) }}
          </div>
        </div>
        <div class="select-wrapper">
          <input-verify
            v-validate="'required'"
            v-model.trim="columnInfo[outputName]"
            :placeholder="$t('editing.pleaseEnterName')"
            :name="'input' + columnInfo.id"
            type="text"
          />
          <div
            v-show="errors.has('input' + columnInfo.id)"
            class="error-text"
          >
            {{ errors.first('input' + columnInfo.id) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultSelect from '@/components/select/DefaultSelect'
import InputVerify from '@/components/InputVerify'

export default {
  name: 'OutputColumnSettingCard',
  inject: ['$validator'],
  components: {
    DefaultSelect,
    InputVerify
  },
  props: {
    columnInfo: {
      type: Object,
      default: () => ({})
    },
    isProcessing: {
      type: Boolean,
      default: false
    },
    isMultiDatasetModel: {
      type: Boolean,
      default: false
    },
    optionList: {
      type: Array,
      default: []
    }
  },
  computed: {
    outputName () {
      return this.isMultiDatasetModel ? 'originalDataFrameName' : 'originalName'
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  padding: 18px;
  border-radius: 8px;
  background-color: rgba(72, 84, 84, .95);
  margin-bottom: 15px;
  position: relative;
  display: flex;
  align-items: center;

  .input-field {
    &__input {
      display: flex;

      .select-wrapper {
        &:not(:first-of-type) {
          margin-left: 16px;
        }
      }
    }

    &__select-multi {
      width: 180px;
    }
  }

  ::v-deep .input-verify .input-verify-text {
    margin-bottom: 0;
  }

  ::v-deep .input-verify .input-error {
    position: absolute;
    bottom: 0;
    top: 100%;
  }
}
</style>
