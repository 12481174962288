<template>
  <div class="card">
    <div class="input-field">
      <label class="input-field__label">{{ $t('modelFlow.upload.modelInputArgs') }}</label>
      <div class="input-field__text">
        {{ columnInfo.modelColumnName ? `${columnInfo.modelColumnName} (${columnInfo.columnStatsType})` : columnInfo.modelDataFrameName }}
      </div>
    </div>
    <div
      v-if="isMultiDatasetModel"
      class="input-field"
    >
      <label class="input-field__label ">{{ $t('modelFlow.upload.correspondingDataFrame') }}</label>
      <div class="input-field__input multi-field">
        <svg-icon
          icon-class="go-right"
          class="icon"
        />
        <div class="select-wrapper">
          <default-select
            v-validate="'required'"
            :option-list="optionList"
            :placeholder="$t('miniApp.chooseDataSource')"
            v-model="sourceDataSourceId"
            filterable
            class="input-field__select-multi"
            :name="`${columnInfo.id}-dataSourceId`"
            @change="fetchDataFrameList"
          />
          <div
            v-show="errors.has(`${columnInfo.id}-dataSourceId`)"
            class="error-text"
          >
            {{ errors.first(`${columnInfo.id}-dataSourceId`) }}
          </div>
        </div>
        <div class="select-wrapper">
          <default-select
            v-validate="'required'"
            :option-list="dataFrameOptionList"
            :placeholder="$t('miniApp.chooseDataFrame')"
            :is-disabled="!sourceDataSourceId && !isLoadingDataFrame"
            v-model="columnInfo.dataFrameId"
            :name="`${columnInfo.id}-dataframeId`"
            filterable
            class="input-field__select-multi"
          />
          <div
            v-show="errors.has(`${columnInfo.id}-dataframeId`)"
            class="error-text"
          >
            {{ errors.first(`${columnInfo.id}-dataframeId`) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="input-field"
    >
      <label class="input-field__label ">{{ $t('modelFlow.upload.referencedColumn') }}</label>
      <div class="input-field__input">
        <svg-icon
          icon-class="go-right"
          class="icon"
        />
        <default-select
          v-validate="'required'"
          :option-list="sameStatsTypeDataColumnOptionList"
          :placeholder="placeholder"
          v-model="columnInfo.columnId"
          :name="'select' + columnInfo.id"
          filterable
          class="input-field__select"
        />
        <div
          v-show="errors.has('select' + columnInfo.id)"
          class="error-text"
        >
          {{ errors.first('select' + columnInfo.id) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultSelect from '@/components/select/DefaultSelect'
import InputVerify from '@/components/InputVerify'
import { getDataFrameById } from '@/API/DataSource'

export default {
  name: 'InputColumnSettingCard',
  inject: ['$validator'],
  components: {
    DefaultSelect,
    InputVerify
  },
  props: {
    optionList: {
      type: Array,
      default: () => []
    },
    columnInfo: {
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String,
      default: null
    },
    isMultiDatasetModel: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sourceDataSourceId: null,
      isLoadingDataFrame: false,
      dataFrameOptionList: []
    }
  },
  computed: {
    sameStatsTypeDataColumnOptionList () {
      return this.optionList.filter(column => column.statsType === this.columnInfo.columnStatsType)
    }
  },
  methods: {
    fetchDataFrameList () {
      this.isLoadingDataFrame = true
      getDataFrameById(this.sourceDataSourceId)
        .then(response => {
          this.dataFrameOptionList = []
          this.dataFrameOptionList = response
            .filter(this.isSelectableSourceDataframe)
            .map((frame) => {
              return {
                name: frame.primaryAlias,
                value: frame.id
              }
            })
        }).finally(() => this.isLoadingDataFrame = false)
    },
    isSelectableSourceDataframe (frame) {
      return !frame.generatedByModelFlow && !(frame.joinCount > 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  padding: 18px;
  border-radius: 8px;
  background-color: rgba(72, 84, 84, .95);
  margin-bottom: 15px;
  position: relative;
  display: flex;
  align-items: center;

  .multi-field {
    display: flex;
  }

  ::v-deep .input-field {
    &__input {
      .select-wrapper {
        &:not(:first-of-type) {
          margin-left: 16px;
        }
      }
    }

    &__select-multi {
      width: 180px;
    }
  }
  ::v-deep .input-verify .input-verify-text {
    margin-bottom: 0;
  }

  ::v-deep .input-verify .input-error {
    position: absolute;
    bottom: 0;
    top: 100%;
  }
}
</style>
