import { render, staticRenderFns } from "./OutputColumnSettingCard.vue?vue&type=template&id=79ab3f98&scoped=true&"
import script from "./OutputColumnSettingCard.vue?vue&type=script&lang=js&"
export * from "./OutputColumnSettingCard.vue?vue&type=script&lang=js&"
import style0 from "./OutputColumnSettingCard.vue?vue&type=style&index=0&id=79ab3f98&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79ab3f98",
  null
  
)

export default component.exports