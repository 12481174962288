<template>
  <div class="content-setting-dialog">
    <div class="dialog-body">
      <div class="setting-block">
        <div class="setting-block__title">
          {{ $t('modelFlow.triggerUpdateType') }}
        </div>
        <div
          v-for="status in updateStatusList"
          :key="status.type"
          class="input-radio-group"
        >
          <input
            v-model="updateMode"
            :id="status.type"
            :value="status.type"
            name="status"
            class="input-radio"
            type="radio"
          >
          <label
            :for="status.type"
            class="input-radio-label"
          >{{ status.name }}</label>
        </div>
      </div>
    </div>
    <div class="dialog-footer">
      <div class="dialog-button-block">
        <button
          :disabled="isProcessing"
          class="btn btn-outline"
          @click="cancel"
        >
          {{ $t('button.cancel') }}
        </button>
        <button
          :disabled="isProcessing"
          class="btn btn-default"
          @click="build"
        >
          <span v-if="isProcessing">
            <svg-icon
              v-if="isProcessing"
              icon-class="spinner"
            />
            {{ $t('button.processing') }}
          </span>
          <span v-else>{{ $t('button.build') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { createModelFlow } from '@/API/ModelFlow'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'OtherSetting',
  data () {
    return {
      isProcessing: false,
      updateMode: 'MANUAL'
    }
  },
  computed: {
    ...mapState('modelFlowManagement', ['currentUploadFlowInfo']),
    updateStatusList () {
      const updateStatusList = [
        {
          type: 'MANUAL',
          name: this.$t('modelFlow.triggerTypes.manual')
        }
      ]
      if (!this.currentUploadFlowInfo.targetDataframeName) {
        return updateStatusList
      }
      updateStatusList.push({
        type: 'AUTOMATIC',
        name: this.$t('modelFlow.triggerTypes.automatic')
      })
      return updateStatusList
    }
  },
  methods: {
    ...mapMutations('modelFlowManagement', ['updateFlowUploadSuccess', 'updateShowCreateFlowDialog']),
    cancel () {
      this.updateShowCreateFlowDialog(false)
    },
    build () {
      this.isProcessing = true
      createModelFlow({
        ...this.currentUploadFlowInfo,
        triggerType: this.updateMode
      }).then(() => {
        this.updateFlowUploadSuccess(true)
        this.updateShowCreateFlowDialog(false)
      }).catch(() => { this.isProcessing = false })
    }
  }
}
</script>
<style lang="scss" scoped>
.content-setting-dialog {
  .setting-block {
    background: rgba(50, 58, 58, 0.95);
    border-radius: 5px;
    padding: 24px;
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 16px;
    }
  }
}
</style>
